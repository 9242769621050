import * as actions from "../actions/ActionTypes";


const MemoriesReducer = (
    state={
        memories:[],
        isSearch:false,
        viewModal:{visible:false,data:{}},
        totalMemories:0,
        addMemoryModal:false,
        updateModal: {visible:false,data:{}},
        updateMemoryModal:false,
        
        loading:false
    }
    , action) =>{
    switch (action.type) {
        case actions.SET_MEMORIES_ARRAY:
            return Object.assign({}, state, {
                memories:action.data
            });
        case actions.SET_MEMORIES_VIEW_MODAL:
            return Object.assign({}, state, {
                viewModal:action.data
            });
        case actions.SET_TOTAL_MEMORIES:
            return Object.assign({}, state, {
                totalMemories:action.data
            });
        case actions.SET_ADD_MEMORIES_MODAL:
            return Object.assign({}, state, {
                addMemoryModal:action.data
            });
        case actions.SET_ADD_MEMORIES_UPDATE_MODAL:
            return Object.assign({}, state, {
                updateMemoryModal:action.data
            });
            case actions.SET_ADD_MEMORIES_UPDATE_MODAL_VIEW:
                return Object.assign({}, state, {
                    updateModal:action.data
            });

        case actions.SET_MEMORIES_LOADING:
            return Object.assign({}, state, {
                loading:action.data
            });


        default:
            return state;
    }
}
export default MemoriesReducer;






