import { Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Columns } from "./Columns";
import { useDispatch, useSelector } from "react-redux";
import { FilterForm } from "./FilterForm";

const Pictures = () => {
  const dispatch = useDispatch();
  const [checkAuth, setCheckAuth] = useState(true);

  useEffect(() => {
    getAllPictures();
  }, []);
  const getAllPictures = async () => {
    // if (await isAuthorized(["pictures"])) {
    //   setCheckAuth(false);
    //   dispatch(setPicturesLoading(true));
    //   await AllPictures(dispatch);
    //   dispatch(setPicturesLoading(false));
    // }
    setCheckAuth(false);
  };
  const PicturesData = useSelector((state) => state.picturesSate);
  const columns = Columns(dispatch);
  return (
    <>
      <div>
        <Spin spinning={PicturesData.loading}>
          <FilterForm />
          <Table
            columns={columns}
            dataSource={PicturesData?.pictures}
            pagination={{
              pageSize: 10,
            }}
            scroll={{ x: 1300, y: 660 }}
          />
        </Spin>
      </div>
    </>
  );
};

export default Pictures;
