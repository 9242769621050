import { message } from "antd";
import axios from "axios";
import {
  setTotalUsers,
  setUserArray,
  setUserFeatures,
} from "../../actions/UserActions";
import Axios from "../../utils/middleware";
import { api_base_url } from "../../constants/constant";

const accessToken = JSON.parse(localStorage.getItem("userData"));

export const createUser = async (values) => {
  const payload = {
    email: values.username,
    name: values.name,
    roles: [values.roles],
  };

  await Axios.post(`${api_base_url}/api/users/create`, payload);
};

export const AllUsers = (params) => {
  let url = `/api/users/all`;
  url = appendQueryParam(url, params);
  return Axios.get(url);
};

export const UpdateUser = async (payload, id) => {
  return Axios.patch(`${api_base_url}/api/users/${id}`, payload);
};

// export const fetchPermissions = async (dispatch) => {
//   await axios
//     .get(`${process.env.REACT_APP_API_URL}/api/permissions`, {
//       headers: {
//         "ngrok-skip-browser-warning": true,
//         Authorization: "Bearer " + accessToken.access_token,
//       },
//     })
//     .then((response) => {
//       const permissions = response?.data?.map((perm) => ({
//         id: perm.id,
//         name: perm.name,
//       }));
//       dispatch(setUserPermissions(permissions));
//     })
//     .catch((error) => {
//       const errorMessage = error?.response?.data?.message;
//       message.error(`${errorMessage}`);
//     });
// };

export const fetchFeatures = async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/features/all`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((response) => {
      const features = response?.data?.content.map((feature) => ({
        id: feature.id,
        feature_name: feature.feature_name,
      }));
      dispatch(setUserFeatures(features));
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};

export const fetchRoles = () => {
  return Axios.get(`${api_base_url}/api/roles`);
};

export const deleteUser = (id) => {
  return Axios.delete(`${api_base_url}/api/users/${id}`);
};

export const filter = async (dispatch, string) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/users/all?search=${string}`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((data) => {
      dispatch(setUserArray(data?.data?.users));
      dispatch(setTotalUsers(data?.data?.count));
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};

export const filterQueryParams = ({ params, excludedParams }) =>
  Object.fromEntries(
    Object.entries(params).filter(
      ([key, value]) =>
        value !== "" &&
        value !== null &&
        value !== undefined &&
        !Array.isArray(value) &&
        !excludedParams?.includes(key)
    )
  );

export const appendQueryParam = (url, params = {}) => {
  const encodeValue = (value) => {
    return value.join(",");
  };

  for (const [key, value] of Object.entries(params)) {
    if (value !== null && value !== undefined) {
      const encodedKey = key.includes("[]") ? key.replace("[]", "") : key;
      let encodedValue = null;
      if (Array.isArray(value) && value.length > 0) {
        encodedValue = encodeValue(value);
      } else if (Array.isArray(value) && value.length === 0) {
        continue;
      } else {
        encodedValue = encodeURIComponent(value);
      }

      url += `${url.includes("?") ? "&" : "?"}${encodedKey}=${encodedValue}`;
    }
  }

  return url;
};
