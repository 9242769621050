import React, { useState } from 'react'
import Button from "../../components/Button"
import { Col, Form, Row, Select } from 'antd'
import { useDispatch } from 'react-redux'
import './style.css'
import { setTicketLoading } from '../../actions/TicketsAction'
import { AllTickets, filter } from './service'
export const FilterForm = () => {
    const [form]=Form.useForm();
    const [search,setSearch]=useState(false);
  const dispatch=useDispatch();
  const handleSearch = async(values) =>{
    dispatch(setTicketLoading(true))
    await filter(dispatch,values?.search);
    dispatch(setTicketLoading(false))

  }
  const handleReset=async()=>{
    setSearch(false);
    form.resetFields(['search']); 
    dispatch(setTicketLoading(true))
    await AllTickets(dispatch);
    dispatch(setTicketLoading(false))


  }


  return (
    <>
    <Row>
      <Col
      span={12}
      style={{
        display: 'flex',
      }}
      >
                <Form
        onFinish={handleSearch}
        form={form}
        style={{display: 'flex'}}
        >
          <Form.Item
            name='search'
            rules={[
              {
                required: true,
                message: 'required field for search'
              }
            ]}
          >
                  <Select
        placeholder='Select the filter '
        style={{
          width:"300px",
          marginRight:"50px"
        }}
        bordered={true}
        onChange={()=>setSearch(true)}
        options={[
            {
                label:"OPEN",
                value: "open"
            },
            {
                label:"IN PROGRESS",
                value: "in_progress"
            },
            {
                label:"CLOSE",
                value: "close"
            }

        ]}
      />

      </Form.Item>
      <Form.Item>
      <div
      type='submit'
      >
      <Button
      label='Search'
      />
     </div>
     </Form.Item>
     </Form>
     {search &&

     <div
      onClick={handleReset}
      style={{marginLeft:"10px"}}
      >
      <Button
      label='Reset'
      />
     </div>
    }
      </Col>
    </Row>
    </>
  )
}
