import React from "react";
import { Tooltip, Popconfirm } from "antd";
import { EyeOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  setEditTicketModal,
  setTicketLoading,
} from "../../actions/TicketsAction";
import { AllApplications, deleteApplication } from "./service";
import {
  setApplicationLoading,
  setApplicationView,
} from "../../actions/ApplicationActions";
import { extractDate } from "../Tickets/service";

export const Columns = (dispatch) => {
  return [
    {
      title: "Application Id",
      key: "1",
      width: 150,
      render: (_, object) => object?.id,
    },
    {
      title: "Job Id",
      key: "1",
      width: 150,
      render: (_, object) => object?.jobId,
    },

    {
      key: "2",
      title: "Applicant Name",
      width: 150,
      render: (_, object) => object?.name,
    },
    {
      key: "3",
      title: "Expertise",
      width: 150,
      render: (_, object) => object?.expertise,
    },
    {
      key: "3",
      title: "Job Applied for",
      width: 150,
      render: (_, object) => object?.job?.title,
    },

    {
      key: "4",
      title: "Email",
      width: 200,
      render: (_, object) => object?.email,
    },
    {
      key: "4",
      title: "Status",
      width: 150,
      render: (_, object) => {
        return (
          <span
            style={
              object?.status === "open"
                ? { color: "green" }
                : object?.status === "close"
                ? { color: "red" }
                : { color: "orange" }
            }
          >
            {object?.status}
          </span>
        );
      },
    },

    {
      key: "4",
      title: "Created At",
      width: 150,
      render: (_, object) => extractDate(object?.created_at),
    },

    {
      fixed: "right",
      width: 150,
      title: "Actions",
      align: "center",
      render: (_, object, index) => (
        <>
          <Tooltip title="Edit status">
            <EditOutlined
              style={{
                color: "blue",
                cursor: "pointer",
                fontSize: "20px",
                paddingTop: "5px",
              }}
              onClick={() => {
                dispatch(setTicketLoading(true));
                dispatch(setEditTicketModal({ visible: true, data: object }));
                dispatch(setTicketLoading(false));
              }}
            />
          </Tooltip>
          &nbsp; &nbsp;
          <Tooltip title="Download Resume">
            <EyeOutlined
              style={{
                color: "blue",
                cursor: "pointer",
                fontSize: "20px",
                paddingTop: "5px",
              }}
              onClick={() =>
                dispatch(setApplicationView({ visible: true, data: object }))
              }
            />
          </Tooltip>
          &nbsp; &nbsp;
          <Tooltip title="Delete User">
            <Popconfirm
              style={{ zIndex: 10001 }}
              title="Are you sure to delete this Application?"
              onConfirm={async () => {
                dispatch(setApplicationLoading(true));
                await deleteApplication(object?.id);
                await AllApplications(dispatch);
                dispatch(setApplicationLoading(false));
              }}
              okText="Yes"
            >
              <DeleteOutlined
                style={{
                  color: "red",
                  cursor: "pointer",
                  fontSize: "20px",
                  paddingTop: "5px",
                }}
              />
            </Popconfirm>
          </Tooltip>
          &nbsp; &nbsp;
        </>
      ),
      key: "2",
    },
  ];
};
