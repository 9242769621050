import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  AppstoreOutlined,
  LogoutOutlined,
  UserOutlined,
  ProfileOutlined,
  UsergroupAddOutlined,
  FormOutlined,
  StarOutlined,
  ContainerOutlined,
  CodeOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button, theme, Dropdown } from "antd";
import { ContentRoutes } from "./Content";
import { Img } from "./components/Img";
import { useQuery } from "react-query";
import { Password } from "./screens/setPassword/Password";
import { useDispatch } from "react-redux";
import { setProfileData } from "./reducers/UserProfile";
import { meAuth } from "./screens/AuthScreens/Login/service";
import { permissions_list } from "./constants/StaticText";
import useProfileInfo from "./hooks/UseProfile";
const { Header, Sider, Content } = Layout;
const Login = React.lazy(() => import("./screens/AuthScreens/Login/Login"));

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { userPermissions } = useProfileInfo();
  const {
    data: profileData,
    isError,
    isSuccess,
  } = useQuery({
    enabled: location?.pathname !== "/set-password",
    queryKey: ["me"],
    queryFn: meAuth,
    retry: false,
    select: (data) => data?.data?.content,
  });
  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setProfileData({
          email: profileData?.email,
          permissions: profileData?.rolePermissions[0]?.permissions,
          id: profileData?.id,
        })
      );
      if (
        window?.location?.pathname === "/" &&
        userPermissions?.includes(permissions_list["VIEW_USERS"])
      ) {
        navigate("/users");
      } else if (
        window?.location?.pathname === "/" &&
        userPermissions?.includes(permissions_list["VIEW_ROLES"])
      ) {
        navigate("/roles");
      } else if (
        window?.location?.pathname === "/" &&
        userPermissions?.includes(permissions_list["VIEW_JOBS"])
      ) {
        navigate("/jobs");
      } else if (
        window?.location?.pathname === "/" &&
        userPermissions?.includes(permissions_list["VIEW_BLOGS"])
      ) {
        navigate("/blog-listing");
      } else if (
        window?.location?.pathname === "/" &&
        userPermissions?.includes(permissions_list["VIEW_LEADERSHIP"])
      ) {
        navigate("/leadership");
      } else if (
        window?.location?.pathname === "/" &&
        userPermissions?.includes(permissions_list["VIEW_TICKETS"])
      ) {
        navigate("/tickets");
      } else if (
        window?.location?.pathname === "/" &&
        userPermissions?.includes(permissions_list["VIEW_DEVELOPER"])
      ) {
        navigate("/developers");
      } else if (
        window?.location?.pathname === "/" &&
        userPermissions?.includes(permissions_list["VIEW_JOB_APPLICATION"])
      ) {
        navigate("/applications");
      }
      // const paths = {
      //   VIEW_USERS: "/users",
      //   VIEW_ROLES: "/roles",
      //   VIEW_JOBS: "/jobs",
      //   VIEW_BLOGS: "/blogs",
      //   VIEW_LEADERSHIP: "/leadership",
      //   VIEW_TICKETS: "/tickets",
      //   VIEW_DEVELOPER: "/developers",
      //   VIEW_JOB_APPLICATION: "/applications",
      // };

      // if (window?.location?.pathname === "/") {
      //   for (const [permission, path] of Object.entries(paths)) {
      //     if (userPermissions?.includes(permissions_list[permission])) {
      //       navigate(path);
      //       break;
      //     }
      //   }
      // }
    } else if (isError) {
      localStorage.removeItem("userData");
      navigate("/login");
    }
  }, [navigate, isSuccess, isError, profileData, dispatch, userPermissions]);
  const menuItem = [
    {
      key: "/dashboard",
      icon: <AppstoreOutlined style={{ fontSize: "25px" }} />,
      label: "Dashboard",
      visible:
        userPermissions?.includes(permissions_list["VIEW_USERS"]) &&
        userPermissions?.includes(permissions_list["VIEW_BLOGS"]),
    },
    {
      key: "/users",
      icon: <UserOutlined style={{ fontSize: "25px" }} />,
      label: "Users",
      visible: userPermissions?.includes(permissions_list["VIEW_USERS"]),
    },
    {
      key: "/roles",
      icon: <ProfileOutlined style={{ fontSize: "25px" }} />,
      label: "Roles",
      visible: userPermissions?.includes(permissions_list["VIEW_ROLES"]),
    },
    {
      icon: <UsergroupAddOutlined style={{ fontSize: "25px" }} />,
      label: "Jobs",
      visible:
        userPermissions?.includes(permissions_list["CREATE_JOBS"]) ||
        userPermissions?.includes(permissions_list["VIEW_JOBS"]),
      children: [
        {
          label: "Job Posting",
          key: "/job-post",
          visible: userPermissions?.includes(permissions_list["CREATE_JOBS"]),
        },
        {
          label: "Job Listing",
          key: "/jobs",
          visible: userPermissions?.includes(permissions_list["VIEW_JOBS"]),
        },
      ],
    },
    {
      icon: <FormOutlined style={{ fontSize: "25px" }} />,
      label: "Blogs",
      visible:
        userPermissions?.includes(permissions_list["CREATE_BLOGS"]) ||
        userPermissions?.includes(permissions_list["VIEW_BLOGS"]),
      children: [
        {
          label: "Blog Posting",
          key: "/blog-post",
          visible: userPermissions?.includes(permissions_list["CREATE_BLOGS"]),
        },
        {
          label: "Blog Listing",
          key: "/blog-listing",
          visible: userPermissions?.includes(permissions_list["VIEW_BLOGS"]),
        },
      ],
    },
    {
      key: "/leadership",
      icon: <StarOutlined style={{ fontSize: "25px" }} />,
      label: "Leadership",
      visible: userPermissions?.includes(permissions_list["VIEW_LEADERSHIP"]),
    },
    {
      key: "/tickets",
      icon: <ContainerOutlined style={{ fontSize: "25px" }} />,
      label: "Ticket Management",
      visible: userPermissions?.includes(permissions_list["VIEW_TICKETS"]),
    },
    {
      key: "/developers",
      icon: <CodeOutlined style={{ fontSize: "25px" }} />,
      label: "Hired Developers",
      visible: userPermissions?.includes(permissions_list["VIEW_DEVELOPER"]),
    },
    {
      key: "/applications",
      icon: <FileAddOutlined style={{ fontSize: "25px" }} />,
      label: "Job Applications",
      visible: userPermissions?.includes(
        permissions_list["VIEW_JOB_APPLICATION"]
      ),
    },
    {
      key: "/life-at-pikessoft",
      icon: <FileAddOutlined style={{ fontSize: "25px" }} />,
      label: "Life at Pikessoft",
      visible: userPermissions?.includes(
        permissions_list["VIEW_JOB_APPLICATION"]
        //!!!!!!!!!!!!!!!!!!!!!UPDATE AND UNCOMMENT IT!!!!!!!!!!!!!!!!!!!!!!!!!!
        // permissions_list["VIEW_LIFE_AT_PIKESSOFT"]
      ),
    },
    // {
    //   key: "/pictures",
    //   icon: <FileImageOutlined style={{ fontSize: "25px" }} />,
    //   label: "Pictures",
    //   visible: userPermissions?.includes(permissions_list["VIEW_PICTURES"]),
    // },
  ];
  const visibleMenuItems = menuItem
    ?.filter((item) => item?.visible)
    ?.map((mainItem) => {
      if (mainItem?.children?.length > 0) {
        const visibleChildren = mainItem?.children?.filter(
          (childItem) => childItem?.visible
        );

        return {
          ...mainItem,
          children: visibleChildren,
          visible: visibleChildren?.length > 0,
        };
      }

      return mainItem;
    })
    ?.filter((item) => item?.visible);
  useEffect(() => {
    const userData = localStorage.getItem("userData");
    let data = JSON.parse(userData);
    if (data?.access_token) {
      setIsAuthenticated(true);
    }
  }, []);

  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const handleSelect = (item) => {
    navigate(item);
  };
  const menu = (
    <Menu>
      <Menu.Item
        key="logout"
        onClick={() => {
          localStorage.removeItem("userData");
          window.location.href = "/login";
        }}
      >
        <LogoutOutlined /> Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {!isAuthenticated && (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/set-password" element={<Password />} />
        </Routes>
      )}
      {isAuthenticated && (
        <Layout>
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            style={{ fontSize: "20px", height: "100vh" }}
            width={300}
          >
            <div
              className="logo"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 5,
              }}
            >
              <div>{collapsed ? "" : <Img src="images/pikeslogo.svg" />}</div>
            </div>
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={[`${location.pathname}`]}
              onSelect={(item) => handleSelect(item?.key)}
              items={visibleMenuItems}
            />
          </Sider>
          <Layout>
            <Header
              style={{
                padding: 0,
                background: colorBgContainer,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
              <Dropdown overlay={menu} placement="bottomRight">
                <Button
                  icon={<LogoutOutlined />}
                  size="large"
                  type="text"
                  className="mr-[40px]"
                >
                  Admin
                </Button>
              </Dropdown>
            </Header>
            <Content
              style={{
                margin: "24px 16px",
                padding: 24,
                height: "80vh",
                background: colorBgContainer,
                overflowY: "auto",
              }}
            >
              <ContentRoutes />
            </Content>
          </Layout>
        </Layout>
      )}
    </>
  );
};

export default App;
