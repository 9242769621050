import React, { useEffect, useState } from "react";
import { Spin, Table } from "antd";
import { Columns } from "./Columns";
import { useDispatch, useSelector } from "react-redux";
import { AllLeadership } from "./service";
import { setLeadershipLoading } from "../../actions/LeadershipActions";
import { FilterForm } from "./FilterForm";

const Leadership = () => {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const LeadershipData = useSelector((state) => state.leadershipState);

  useEffect(() => {
    getAllLeadership();
  }, []);

  const getAllLeadership = async () => {
    dispatch(setLeadershipLoading(true));
    await AllLeadership(dispatch);
    dispatch(setLeadershipLoading(false));
    const leadershipWithPosition = LeadershipData?.leadership.map(
      (item, index) => ({
        ...item,
        position: index + 1,
      })
    );
    setDataSource(leadershipWithPosition || []);
  };

  const columns = Columns(dispatch, dataSource, setDataSource);

  return (
    <Spin spinning={LeadershipData.loading}>
      <FilterForm />
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSize: 10,
        }}
        scroll={{ x: 1300, y: 660 }}
        rowKey="id"
      />
    </Spin>
  );
};

export default Leadership;
