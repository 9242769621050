import * as actions from "./ActionTypes";

export const setMemoriesArray=(data)=>{
    return {
        type:actions.SET_MEMORIES_ARRAY,
        data
    }}

export const setMemoriesSearch=(data)=>({
    type:actions.SET_MEMORIES_LOADING,
    data
})

export const setMemoriesViewMOdal=(data)=>({
    type:actions.SET_MEMORIES_VIEW_MODAL,
    data
})

export const setTotalMemories=(data)=>({
    type:actions.SET_TOTAL_MEMORIES,
    data
})

export const setAddMemoriesModal=(data)=>{
    return{
        type:actions.SET_ADD_MEMORIES_MODAL,
        data
    }}

export const setMemoriesLoading=(data)=>{
    return{
        type:actions.SET_MEMORIES_LOADING,
        data
    }}



