
//user actions
export const SET_USERS_ARRAY='SET_USERS_ARRAY';
export const SET_USER_SEARCH='SET_USER_SEARCH';
export const SET_USER_VIEW_MODAL='SET_USER_VIEW_MODAL';
export const SET_TOTAL_USERS='SET_TOTAL_USERS';
export const SET_ADD_USER_MODAL='SET_ADD_USER_MODAL';
export const SET_USER_LOADING='SET_USER_LOADING';
export const SET_USER_PERMISSIONS='SET_USER_PERMISSIONS';
export const SET_USER_ROLES='SET_USER_ROLES';
export const SET_USER_FEATUREs = 'SET_USER_FEATURES'

//jobs management

export const SET_JOB_ARRAY='SET_JOBS_ARRAY';
export const SET_JOB_SEARCH='SET_JOB_SEARCH';
export const SET_JOB_VIEW_MODAL='SET_JOB_VIEW_MODAL';
export const SET_TOTAL_JOB='SET_TOTAL_JOBS';
export const SET_ADD_JOB_MODAL='SET_ADD_JOB_MODAL';
export const SET_JOB_LOADING='SET_JOB_LOADING';


//blogs 


export const SET_BLOG_ARRAY='SET_BLOG_ARRAY';

export const SET_BLOG_SEARCH='SET_JOB_SEARCH';
export const SET_BLOG_VIEW_MODAL='SET_JOB_VIEW_MODAL';
export const SET_TOTAL_BLOG='SET_TOTAL_JOBS';
export const SET_ADD_BLOG_MODAL='SET_ADD_JOB_MODAL';
export const SET_BLOG_LOADING='SET_JOB_LOADING';
export const SET_EDIT_BLOG_MODAL ='SET_EDIT_BLOG_MODAL';
export const SET_EDIT_BLOG_DATA ='SET_EDIT_BLOG_DATA';


//Tickets

export const SET_TICKETS_ARRAY='SET_TICKETS_ARRAY';
export const SET_TICKET_VIEW_MODAL='SET_TICKET_VIEW_MODAL';
export const SET_EDIT_TICKET_MODAL='SET_EDIT_TICKET_MODAL';
export const SET_TICKET_LOADING='SET_TICKET_LOADING';

//Application

export const SET_APPLICATION_ARRAY='SET_TICKETS_ARRAY';
export const SET_EDIT_APPLICATION_MODAL='SET_EDIT_TICKET_MODAL';
export const SET_APPLICATION_LOADING='SET_TICKET_LOADING';
export const SET_APPLICATION_VIEW='SET_APPLICATION_VIEW';

//Leadership

export const SET_LEADERSHIP_ARRAY='SET_LEADERSHIP_ARRAY';
export const SET_LEADERSHIP_SEARCH='SET_LEADERSHIP_SEARCH';
export const SET_LEADERSHIP_VIEW_MODAL='SET_LEADERSHIP_VIEW_MODAL';
export const SET_TOTAL_LEADERSHIP='SET_TOTAL_LEADERSHIP';
export const SET_ADD_LEADERSHIP_MODAL='SET_ADD_LEADERSHIP_MODAL';
export const SET_LEADERSHIP_LOADING='SET_LEADERSHIP_LOADING';

//Developer
export const SET_DEVELOPER_ARRAY='SET_DEVELOPER_ARRAY';
export const SET_DEVELOPER_SEARCH='SET_DEVELOPER_SEARCH';
export const SET_DEVELOPER_VIEW_MODAL='SET_DEVELOPER_VIEW_MODAL';
export const SET_TOTAL_DEVELOPER='SET_TOTAL_DEVELOPER';
export const SET_ADD_DEVELOPER_MODAL='SET_ADD_DEVELOPER_MODAL';
export const SET_DEVELOPER_LOADING='SET_DEVELOPER_LOADING';

//Pictures
export const SET_PICTURES_ARRAY='SET_PICTURES_ARRAY';
export const SET_PICTURES_VIEW_MODAL='SET_PICTURES_VIEW_MODAL';
export const SET_TOTAL_PICTURES='SET_TOTAL_PICTURES';
export const SET_ADD_PICTURES_MODAL='SET_ADD_PICTURES_MODAL';
export const SET_ADD_PICTURES_UPDATE_MODAL='SET_ADD_PICTURES_UPDATE_MODAL';
export const SET_ADD_PICTURES_UPDATE_MODAL_VIEW='SET_ADD_PICTURES_UPDATE_MODAL_VIEW';
export const SET_PICTURES_LOADING='SET_PICTURES_LOADING';

//Memories
export const SET_MEMORIES_ARRAY='SET_MEMORIES_ARRAY';
export const SET_MEMORIES_VIEW_MODAL='SET_MEMORIES_VIEW_MODAL';
export const SET_TOTAL_MEMORIES='SET_TOTAL_MEMORIES';
export const SET_ADD_MEMORIES_MODAL='SET_ADD_MEMORIES_MODAL';
export const SET_ADD_MEMORIES_UPDATE_MODAL='SET_ADD_MEMORIES_UPDATE_MODAL';
export const SET_ADD_MEMORIES_UPDATE_MODAL_VIEW='SET_ADD_MEMORIES_UPDATE_MODAL_VIEW';
export const SET_MEMORIES_LOADING='SET_MEMORIES_LOADING';
export const SET_MEMORIES_SEARCH='SET_MEMORIES_SEARCH';


//roles
export const SET_FEATURES_ARRAY = 'SET_FEATURES_ARRAY'
export const SET_TOTAL_FEATURES='SET_TOTAL_FEATURES';
export const SET_ADD_ROLES_MODAL='SET_ADD_ROLES_MODAL';
export const SET_ROLES_LOADING='SET_ROLES_LOADING';
export const SET_ROLES_VIEW_MODAL='SET_ROLES_VIEW_MODAL';
export const SET_ROLES_FEATURE = 'SET_ROLES_FEATURE'