import { Spin, Table } from "antd";
import React, { useEffect, useMemo } from "react";
import { Columns } from "./Columns";
import { useDispatch, useSelector } from "react-redux";
import { AllUsers, filterQueryParams } from "./service";
import { FilterForm } from "./FilterForm";
import { useQuery } from "react-query";
import { resetUserQueryParams } from "../../reducers/UserProfile";

const Users = () => {
  const dispatch = useDispatch();
  const { userQueryParams } = useSelector((state) => state.userProfile);
  useEffect(() => {
    return () => {
      dispatch(resetUserQueryParams());
    };
  }, [dispatch]);

  const { take, skip, ...searchParams } = useMemo(() => {
    let params = { ...userQueryParams };

    params = filterQueryParams({
      params,
      excludedParams: ["currentPage"],
    });

    return { ...params };
  }, [userQueryParams]);
  const {
    data: UsersData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["users", { ...searchParams }],
    queryFn: ({ queryKey }) => {
      const [, params] = queryKey;
      return AllUsers(params);
    },
    select: (res) => res?.data,
  });

  const columns = Columns(dispatch, refetch);
  return (
    <>
      <div>
        <Spin spinning={!!isLoading}>
          <FilterForm refetch={refetch} />
          <Table
            columns={columns}
            dataSource={UsersData?.users}
            pagination={{
              pageSize: 10,
            }}
            scroll={{ x: 1300, y: 660 }}
          />
        </Spin>
      </div>
    </>
  );
};

export default Users;
