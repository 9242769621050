import React from "react";
import Button from "../../components/Button";
import { useDispatch } from 'react-redux'
import { setAddMemoriesModal } from "../../actions/MemoriesActions";

const FilterForm = () => {
    const dispatch=useDispatch();

    const onAddMemory = () =>{
        dispatch(setAddMemoriesModal(true))
    }



  return (<>
    <div style={{ marginBottom: 16, float:"right" }}>
        <div onClick={onAddMemory}>
              <Button label="Add Memory" />
            </div>
    </div>   
 </> );
};

export default FilterForm; 