import React from "react";
import { Tooltip, Popconfirm, Checkbox, Button } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {
  setAddLeadershipModal,
  setLeadershipLoading,
  setLeadershipViewMOdal,
} from "../../actions/LeadershipActions";
import {
  AllLeadership,
  deleteLeadership,
  Activate,
  shiftLeadership,
} from "./service";
import useProfileInfo from "../../hooks/UseProfile";
import { permissions_list } from "../../constants/StaticText";

export const Columns = (dispatch, dataSource, setDataSource) => {
  const { userPermissions } = useProfileInfo();

  const handleActivate = async (object) => {
    const flag = object?.status === "active" ? true : false;
    dispatch(setLeadershipLoading(true));
    await Activate(object, flag);
    await AllLeadership(dispatch);
    dispatch(setLeadershipLoading(false));
  };

  const moveRow = async (object, direction) => {
    const currentIndex = dataSource.findIndex((item) => item.id === object.id);
    if (direction === "Up" && currentIndex === 0) return;
    if (direction === "Down" && currentIndex === dataSource.length - 1) return;

    const newData = [...dataSource];
    const swapIndex = direction === "Up" ? currentIndex - 1 : currentIndex + 1;

    [newData[currentIndex], newData[swapIndex]] = [
      newData[swapIndex],
      newData[currentIndex],
    ];

    setDataSource(newData);

    await shiftLeadership(dispatch, object.id, direction);
  };

  return [
    {
      title: "Id",
      key: "1",
      width: 150,
      render: (_, object) => object?.id,
    },
    {
      key: "2",
      title: "Name",
      width: 150,
      render: (_, object) => object?.name,
    },
    {
      key: "3",
      title: "Designation",
      width: 150,
      render: (_, object) => object?.designation,
    },
    {
      key: "4",
      title: "Color",
      width: 150,
      render: (_, object) => object?.color,
    },
    {
      key: "5",
      title: "Status",
      width: 150,
      render: (_, object) => (
        <Popconfirm
          okText="Yes"
          cancelText="No"
          title={
            object?.status === "inactive"
              ? "Do You Want to Activate this Leadership"
              : "Do You Want to Deactivate this Leadership"
          }
          onConfirm={() => handleActivate(object)}
        >
          <Checkbox checked={object?.status === "inactive" ? false : true} />
        </Popconfirm>
      ),
    },
    {
      fixed: "right",
      width: 150,
      title: "Actions",
      align: "center",
      render: (_, object) => (
        <>
          {userPermissions?.includes(permissions_list["UPDATE_LEADERSHIP"]) && (
            <>
              <Tooltip title="Move Up">
                <Button
                  icon={<UpOutlined />}
                  onClick={() => moveRow(object, "Up")}
                  disabled={
                    dataSource.findIndex((item) => item.id === object.id) === 0
                  }
                />
              </Tooltip>
              &nbsp;
              <Tooltip title="Move Down">
                <Button
                  icon={<DownOutlined />}
                  onClick={() => moveRow(object, "Down")}
                  disabled={
                    dataSource.findIndex((item) => item.id === object.id) ===
                    dataSource.length - 1
                  }
                />
              </Tooltip>
              &nbsp;
              <Tooltip title="Detail">
                <EditOutlined
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    fontSize: "20px",
                    paddingTop: "5px",
                  }}
                  onClick={() => {
                    dispatch(
                      setLeadershipViewMOdal({ visible: true, data: object })
                    );
                    dispatch(setAddLeadershipModal(true));
                  }}
                />
              </Tooltip>
            </>
          )}
          &nbsp; &nbsp;
          {userPermissions?.includes(permissions_list["DELETE_LEADERSHIP"]) && (
            <Tooltip title="Delete Leadership">
              <Popconfirm
                style={{ zIndex: 10001 }}
                title="Are you sure to delete this Leadership?"
                onConfirm={async () => {
                  dispatch(setLeadershipLoading(true));
                  await deleteLeadership(dispatch, object?.id);
                  await AllLeadership(dispatch);
                  dispatch(setLeadershipLoading(false));
                }}
                okText="Yes"
                okType="primary"
                cancelText="No"
              >
                <DeleteOutlined
                  style={{
                    color: "red",
                    cursor: "pointer",
                    fontSize: "20px",
                    paddingTop: "5px",
                  }}
                />
              </Popconfirm>
            </Tooltip>
          )}
        </>
      ),
      key: "actions",
    },
  ];
};
