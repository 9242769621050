import React from "react";
import { Tooltip, Popconfirm, Checkbox } from "antd";
import { EyeOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  setBlogLoading,
} from "../../actions/BlogActions";
import { AllBlogs, deleteBlog, Activate } from "./service";
import { base_url } from "../../constants/constant";
import { extractDate } from "../Tickets/service";

export const Columns = (dispatch, navigate) => {
  const handleActivate = async (object) => {
    const flag = object?.status === "published" ? true : false;
    dispatch(setBlogLoading(true));
    await Activate(object, flag);
    await AllBlogs(dispatch);
    dispatch(setBlogLoading(false));
  };

  return [
    {
      title: "Id",
      key: "1",
      width: 150,
      render: (_, object) => object?.id,
    },
    {
      key: "2",
      title: "Title",
      width: 150,
      render: (_, object) => object?.title,
    },
    {
      key: "3",
      title: "Image",
      width: 150,
      render: (_, object) => object?.image,
    },
    {
      key: "5",
      title: "status",
      width: 150,
      render: (_, object) => (
        <Popconfirm
          okText="Yes"
          cancelText="No"
          title={
            object?.status === "draft"
              ? "Do You Want to Activate this Blog"
              : "Do You Want to Deactivate this Blog"
          }
          onConfirm={() => handleActivate(object)}
        >
          <Checkbox checked={object?.status === "draft" ? false : true} />
        </Popconfirm>
      ),
    },
    {
      key: "6",
      title: "Created At",
      width: 150,
      render: (_, object) => extractDate(object?.created_at),
    },
    {
      key: "7",
      title: "Updated At",
      width: 150,
      render: (_, object) => extractDate(object?.updated_at),
    },
    {
      key: "5",
      title: "Created By",
      width: 150,
      render: (_, object) => object?.created_by?.name,
    },
    {
      key: "5",
      title: "Updated By",
      width: 150,
      render: (_, object) => object?.updated_by?.name,
    },
    {
      fixed: "right",
      width: 150,
      title: "Actions",
      align: "center",
      render: (_, object, index) => (
        <>
          <Tooltip title="Detail">
            <a
              href={`${base_url}blog-details?id=${object?.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <EyeOutlined
                style={{
                  color: "blue",
                  cursor: "pointer",
                  fontSize: "20px",
                  paddingTop: "5px",
                }}
              />
            </a>
          </Tooltip>
          &nbsp; &nbsp;
          <Tooltip title="Edit Blog">
            <EditOutlined
              style={{
                color: "blue",
                cursor: "pointer",
                fontSize: "20px",
                paddingTop: "5px",
              }}
              onClick={() => {
                navigate("/blog-post", {
                  state: {
                    id: object?.id,
                  },
                });
              }}
            />
          </Tooltip>
          &nbsp; &nbsp;
          <Tooltip title="Delete Blog">
            <Popconfirm
              style={{ zIndex: 10001 }}
              title="Are you sure to delete this Blog?"
              onConfirm={async () => {
                dispatch(setBlogLoading(true));
                await deleteBlog(dispatch, object?.id);
                await AllBlogs(dispatch);
                dispatch(setBlogLoading(false));
              }}
              okText="Yes"
              okType="primary"
              cancelText="No"
            >
              <DeleteOutlined
                style={{
                  color: "red",
                  cursor: "pointer",
                  fontSize: "20px",
                  paddingTop: "5px",
                }}
              />
            </Popconfirm>
          </Tooltip>
          &nbsp; &nbsp;
        </>
      ),
      key: "8",
    },
  ];
};
