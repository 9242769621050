import React, { useState } from "react";
import { presetPalettes} from '@ant-design/colors';
import { Form, Input, Select, Spin, ColorPicker } from "antd";
import Button from "../../components/Button";
import { AllMemories, AddMemory,UpdateMemory } from "./services";
import { setMemoriesLoading } from "../../actions/MemoriesActions";
import { s3_bucket_url } from "../../constants/constant";
import { useDispatch, useSelector } from "react-redux";
import { ImCross } from "react-icons/im";

const { Option } = Select;

const AddMemoryForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.memoryState);

  function genPresets(presets = presetPalettes) {
    return Object.entries(presets).map(([label, colors]) => ({
      label,
      colors,
      key: label,
    }));
  }

  const presets = genPresets({
  });


  const [image, setImage] = useState(
    state?.viewModal?.visible ? state?.viewModal?.data?.image : null
  );
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleSubmit = async (values) => {
    dispatch(setMemoriesLoading(true));
    await AddMemory(dispatch, values, image);
    await AllMemories(dispatch);
    dispatch(setMemoriesLoading(false));
  };

  const handleEdit = async (values) => {
    dispatch(setMemoriesLoading(true));
    await UpdateMemory(dispatch, values, state?.viewModal?.data?.id);
    await AllMemories(dispatch);
    dispatch(setMemoriesLoading(false));
  };

  const handleDelete = () => {
    setImage(null);
  };


  return (
    <Spin spinning={state.loading}>
      <div className="flex flex-col items-center justify-center items-center text-xl">
        {!state?.viewModal?.visible && <span>Add New Memory</span>}
        {state?.viewModal?.visible && <span>Edit Memory</span>}
        <Form
          form={form}
          layout="vertical"
          onFinish={state?.viewModal?.visible ? handleEdit : handleSubmit}
          style={{ width: "85%", marginTop: "50px" }}
          initialValues={{
            description: state?.viewModal?.visible
              ? state?.viewModal?.data?.description
              : "",
            status: state?.viewModal?.visible
              ? state?.viewModal?.data?.status
              : "",
              color: state?.viewModal?.visible
              ? state?.viewModal?.data?.color
              : "#1677ff"
          }}
        >
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "Please enter a description!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: "Please select a status!" }]}
          >
            <Select placeholder="Select a status">
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="color"
            label="color"            
          >
            <ColorPicker presets={presets}
            defaultValue={state?.viewModal?.visible?state?.viewModal?.visible:'#1677ff'}
            format="hex"
            onChange={(color, hex) => {
              form.setFieldValue('color', hex);
            }}/>
          </Form.Item>
          {!state?.viewModal?.visible &&
          <Form.Item
            name="image"
            label="Image Upload"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            {typeof image !== "string" && (
              <input
                name="image"
                type="file"
                accept="image/*"
                onChange={(e) => handleImageChange(e)}
              />
            )}
            {image && typeof image === "string" && (
              <div
                style={{
                  position: "relative",
                  width: "100px",
                  height: "100px",
                  borderRadius: "5px",
                  margin: "10px",
                }}
              >
                <ImCross
                  style={{
                    position: "absolute",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    color: "white",
                    padding: "5px",
                    fontSize: "20px",
                    cursor: "pointer",
                    right: "-3px",
                    top: "-3px",
                  }}
                  onClick={handleDelete}
                />
                <img
                  src={`${s3_bucket_url}${image}`}
                  alt="LeaderImage"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "5px",
                    objectFit: "cover",
                  }}
                />
              </div>
            )}          
          </Form.Item>}
          <Form.Item>
            <Button type="primary" htmlType="submit"   label={state?.viewModal?.visible ? 'Edit Memory' : 'Add Memory'} />
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};

export default AddMemoryForm;
