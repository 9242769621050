import { message } from "antd";
import axios from "axios";
import {
  setAddMemoriesModal,
  setMemoriesArray,
  setTotalMemories,
} from "../../actions/MemoriesActions";

const accessToken = JSON.parse(localStorage.getItem("userData"));

export const AddMemory = async (dispatch, values, image) => {
  const formParams = new FormData();

  if (values.description) {
    formParams.append("description", values.description);
  } else {
    console.error("Description is missing");
  }

  if (values.color) {
    formParams.append("color", values.color);
  } else {
    console.error("Color is missing, setting default 'red'");
    formParams.append("color", "red"); // Ensure color is always present
  }

  if (values.status) {
    formParams.append("status", values.status);
  } else {
    console.error("Status is missing");
  }

  if (image instanceof File) {
    formParams.append("file", image);
  } else {
    console.error("File is missing or not a valid File object");
  }

  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/api/memories`,
      formParams,
      {
        headers: {
          "ngrok-skip-browser-warning": true,
          Authorization: "Bearer " + accessToken.access_token,
          // No need to set 'Content-Type', Axios handles it
        },
      }
    );

    dispatch(setAddMemoriesModal(false));
    message.success("Memory created successfully");
  } catch (error) {
    console.error("API Error:", error.response?.data || error);
    const errorMessage = error?.response?.data?.message || "An error occurred";
    message.error(`${errorMessage}`);
  }
};

export const UpdateMemory = async (dispatch, values, id) => {
  const payload = {
    description: values.description,
    color: values.color,
    status: values.status,
  };

  await axios
    .patch(`${process.env.REACT_APP_API_URL}/api/memories/${id}`, payload, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((response) => {
      dispatch(setAddMemoriesModal(false));
      message.success("Memory Updated Successfully");
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};

export const AllMemories = async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/memories`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((data) => {
      dispatch(setMemoriesArray(data?.data?.content));
      dispatch(setTotalMemories(data?.data?.count));
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};

export const reorderMemories = async (reorderedList) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/api/memories/reorder`,
      reorderedList,
      {
        headers: {
          "ngrok-skip-browser-warning": true,
          Authorization: "Bearer " + accessToken.access_token,
        },
      }
    );
    message.success("Order updated successfully");
  } catch (error) {
    message.error("Failed to update order");
  }
};

export const deleteMemories = async (dispatch, id) => {
  await axios
    .delete(`${process.env.REACT_APP_API_URL}/api/memories/${id}`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((response) => {
      message.success("Memory Deleted Successfully");
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};
