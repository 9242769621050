import React, { useState } from "react";
import Button from "../../components/Button";
import { Col, Form, Input, Row, Select } from "antd";
import { useDispatch } from "react-redux";
import "./style.css";
import { AllApplications, Search, filter } from "./service";
import { setApplicationLoading } from "../../actions/ApplicationActions";
export const FilterForm = () => {
  const [form] = Form.useForm();
  const [search, setSearch] = useState(false);
  const dispatch = useDispatch();
  const handleSearch = async (values) => {
    dispatch(setApplicationLoading(true));
    await filter(dispatch, values?.search);
    dispatch(setApplicationLoading(false));
  };
  const handleReset = async () => {
    setSearch(false);
    form.resetFields(["search"]);
    dispatch(setApplicationLoading(true));
    await AllApplications(dispatch);
    dispatch(setApplicationLoading(false));
  };
  const handleFilter = async (e) => {
    if(e==='all'){
      dispatch(setApplicationLoading(true));
      await AllApplications(dispatch);
      dispatch(setApplicationLoading(false));  
    }else{
      dispatch(setApplicationLoading(true));
      await Search(dispatch,e);
      dispatch(setApplicationLoading(false));
  
    }
  };
  return (
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
          }}
        >
          <Form onFinish={handleSearch} form={form} style={{ display: "flex" }}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: true,
                  message: "required field for search",
                },
              ]}
            >
              <Input
                placeholder="Search"
                style={{
                  width: "300px",
                  marginRight: "50px",
                }}
                bordered={true}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <div type="submit">
                <Button label="Search" />
              </div>
            </Form.Item>
          </Form>
          {search && (
            <div onClick={handleReset} style={{ marginLeft: "10px" }}>
              <Button label="Reset" />
            </div>
          )}
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent:'flex-end'
          }}
        >
          <Select
            defaultValue={"all"}
            placeholder="Select the filter "
            style={{
              width: "200px",
              marginRight: "50px",
            }}
            onSelect={(e) => handleFilter(e)}
            options={[
              {
                label: "All",
                value: "all",
              },
              {
                label: "OPEN",
                value: "open",
              },
              {
                label: "CLOSE",
                value: "close",
              },
            ]}
          />
        </Col>
      </Row>
  );
};
