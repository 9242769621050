import { combineReducers } from "@reduxjs/toolkit";
import UserReducers from "./UserReducers";
import JobsReducers from "./JobsReducers";
import blogReducers from "./BlogReducer";
import TicketReducers from "./TicketReducer";
import ApplicationReducers from "./ApplicationReducer";
import LeadershipReducer from "./LeadershipReducer";
import DeveloperReducer from "./DeveloperReducer";
import PicturesReducers from "./PicturesReducer";
import RolesReducer from "./RolesReducers";
import UserProfileReducer from "./UserProfile";
import MemoriesReducer from "./MemoriesReducer";

const allReducers = combineReducers({
  userState: UserReducers,
  jobState: JobsReducers,
  blogState: blogReducers,
  ticketState: TicketReducers,
  applicationState: ApplicationReducers,
  leadershipState: LeadershipReducer,
  developerState: DeveloperReducer,
  picturesSate: PicturesReducers,
  rolesState: RolesReducer,
  userProfile: UserProfileReducer,
  memoryState:MemoriesReducer
});

export default allReducers;
