import React, { useEffect, useContext, useState, useMemo, createContext } from "react";
import { Spin, Tooltip, Table, Button, Modal } from "antd";
import { EditOutlined, HolderOutlined } from "@ant-design/icons";
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useDispatch, useSelector } from "react-redux";
import { setMemoriesLoading,setAddMemoriesModal, setMemoriesViewMOdal } from "../../actions/MemoriesActions";
import { s3_bucket_url } from "../../constants/constant";

import { AllMemories,reorderMemories } from "./services";
import FilterForm from "./FilterForm";
import AddMemoryForm from "./AddMemoryForm";
import './style.css'

// Row context for drag handle
const RowContext = createContext({});

// Draggable row component
const Row = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'default',
    ...(isDragging ? {
      position: 'relative',
      zIndex: 9999,
    } : {}),
  };

  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners],
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const LifeAtPikessoft = () => {
  const dispatch = useDispatch();
  const MemoryData = useSelector((state) => state.memoryState);
  const [dataSource, setDataSource] = useState(MemoryData?.memories || []);

  useEffect(() => {
    getAllMemories();
  }, []);

  useEffect(() => {
    if (MemoryData?.memories?.length > 0) {
      const memoriesWithPosition = MemoryData?.memories?.map((item, index) => ({
        ...item,
        position: index + 1,
      }));
      setDataSource(memoriesWithPosition);
    }
  }, [MemoryData.memories]);

  const getAllMemories = async () => {
    dispatch(setMemoriesLoading(true));
    await AllMemories(dispatch);    
    dispatch(setMemoriesLoading(false));
  };

  const DragHandle = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return (
      <Button
        type="text"
        icon={<HolderOutlined />}
        style={{ cursor: 'move',border:"none !important" }}
        ref={setActivatorNodeRef}
        {...listeners}
      />
    );
  };

  const onDragEnd = async ({ active, over }) => {
    if (active?.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState?.findIndex((record) => record?.id === active?.id);
        const overIndex = prevState?.findIndex((record) => record?.id === over?.id);
        if (activeIndex === -1 || overIndex === -1) return prevState;

        const newOrder = arrayMove(prevState, activeIndex, overIndex);
        const reorderPayload = newOrder.map((item, index) => ({
          id: item?.id, 
          position: index + 1
        }));
       reorderMemories(reorderPayload);
        
        return newOrder;
      });
    }
  };

  const handleCloseAddMemory = () => {
    if (MemoryData?.viewModal?.visible) {
      dispatch(setAddMemoriesModal(false));
      dispatch(setMemoriesViewMOdal({ visible: false, data: {} }));
    } else {
      dispatch(setAddMemoriesModal(false));
    }
  };
  
  const columns=[
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 60,
      render: () => <DragHandle />,
    },
    {
      title: 'Image',
      dataIndex: 'image',
      render: (_, object) => (
        <img src={s3_bucket_url+object.image} 
        alt="image"
        style={{
          width: "50px",
          height: "50px",
          borderRadius: "5px",
          objectFit: "cover",
        }}/>
      )
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      fixed: "right",
      width: 150,
      title: "Actions",
      align: "center",
      render: (_, object) => (<>     
              <Tooltip title="Detail">
                <EditOutlined
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    fontSize: "20px",
                    paddingTop: "5px",
                  }}
                  onClick={() => {
                    dispatch(
                      setMemoriesViewMOdal({ visible: true, data: object })
                    );
                    dispatch(setAddMemoriesModal(true));
                  }}
                />
              </Tooltip>
      </>),
    },
  ];

  return (<>
    <Spin spinning={MemoryData?.loading}>
      <FilterForm/>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource?.map((i) => i.id)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            components={{
              body: {
                row: Row,
              },
            }}
          />
        </SortableContext>
      </DndContext>
    </Spin>
    <Modal
        open={MemoryData?.addMemoryModal}
        onCancel={handleCloseAddMemory}
        footer={null}
        destroyOnClose={true}
      >
        <AddMemoryForm />
      </Modal>
  </>);
};

export default LifeAtPikessoft;